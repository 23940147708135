import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { encryptData } from "../utils/cryptoUtils";
import BrandLogo from "../assets/static/logo/logo_darkMode.png";
import { useNavigate } from "react-router-dom";
import { footerContentPhoto } from "../global/constants";

const Footer = () => {
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  const eventNavigate = useCallback(
    (data) => {
      const encryptedData = encryptData(data);
      navigate(`/productDetails?id=${encryptedData}`);
    },
    [navigate]
  );

  return (
    <>
      <footer class="footer bg-dark-footer relative text-gray-200 dark:text-gray-200">
        <div class="container relative">
          <div class="grid grid-cols-12">
            <div class="col-span-12">
              <div class="py-[60px] px-0">
                <div class="grid md:grid-cols-12 grid-cols-1 gap-6">
                  <div class="lg:col-span-3 md:col-span-12">
                    <a href="#" class="text-[22px] focus:outline-none">
                      <img
                        src={BrandLogo}
                        className="brandLogoImage inline-block dark:hidden"
                        alt=""
                      />
                    </a>
                    <p class="mt-6 text-gray-300">
                      At Planet 56, we believe every photo tells a unique story.
                      Our passion is to provide high-quality, handcrafted frames
                      that preserve your cherished memories.
                    </p>
                    <ul class="list-none mt-6">
                      <li class="inline">
                        <a
                          href="https://dribbble.com/shreethemes"
                          target="_blank"
                          class="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-gray-800 dark:border-slate-800 rounded-md hover:text-orange-500 dark:hover:text-orange-500 text-slate-300"
                        >
                          <i
                            data-feather="dribbble"
                            class="h-4 w-4 align-middle"
                            title="dribbble"
                          ></i>
                        </a>
                      </li>
                      <li class="inline">
                        <a
                          href="http://linkedin.com/company/shreethemes"
                          target="_blank"
                          class="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-gray-800 dark:border-slate-800 rounded-md hover:text-orange-500 dark:hover:text-orange-500 text-slate-300"
                        >
                          <i
                            data-feather="linkedin"
                            class="h-4 w-4 align-middle"
                            title="Linkedin"
                          ></i>
                        </a>
                      </li>
                      <li class="inline">
                        <a
                          href="https://www.facebook.com/shreethemes"
                          target="_blank"
                          class="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-gray-800 dark:border-slate-800 rounded-md hover:text-orange-500 dark:hover:text-orange-500 text-slate-300"
                        >
                          <i
                            data-feather="facebook"
                            class="h-4 w-4 align-middle"
                            title="facebook"
                          ></i>
                        </a>
                      </li>
                      <li class="inline">
                        <a
                          href="https://www.instagram.com/shreethemes/"
                          target="_blank"
                          class="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-gray-800 dark:border-slate-800 rounded-md hover:text-orange-500 dark:hover:text-orange-500 text-slate-300"
                        >
                          <i
                            data-feather="instagram"
                            class="h-4 w-4 align-middle"
                            title="instagram"
                          ></i>
                        </a>
                      </li>
                      <li class="inline">
                        <a
                          href="https://twitter.com/shreethemes"
                          target="_blank"
                          class="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-gray-800 dark:border-slate-800 rounded-md hover:text-orange-500 dark:hover:text-orange-500 text-slate-300"
                        >
                          <i
                            data-feather="twitter"
                            class="h-4 w-4 align-middle"
                            title="twitter"
                          ></i>
                        </a>
                      </li>
                      <li class="inline">
                        <a
                          href="mailto:support@shreethemes.in"
                          class="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-gray-800 dark:border-slate-800 rounded-md hover:text-orange-500 dark:hover:text-orange-500 text-slate-300"
                        >
                          <i
                            data-feather="mail"
                            class="h-4 w-4 align-middle"
                            title="email"
                          ></i>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div class="lg:col-span-9 md:col-span-12">
                    <h5 class="tracking-[1px] text-gray-100 font-semibold">
                      Photo frames and Industrial sign Boards{" "}
                    </h5>

                    <div class=" d-block d-md-flex  justify-content-between">
                      <div class="">
                        <ul class="list-none footer-list mt-6">
                          {footerContentPhoto &&
                            footerContentPhoto.map((data, index) => (
                              <li
                                onClick={() => eventNavigate(data?.value)}
                                className={`cursorPointer ${
                                  index !== 0 ? "mt-[10px]" : ""
                                }`}
                              >
                                <div
                                  href=""
                                  class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                                >
                                  <i class="mdi mdi-chevron-right"></i>{" "}
                                  {data?.title}
                                </div>
                              </li>
                            ))}
                        </ul>
                      </div>

                      <div class="">
                        <ul class="list-none footer-list mt-6">
                          <li>
                            <a
                              href=""
                              class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                            >
                              <i class="mdi mdi-chevron-right"></i> Shorts{" "}
                            </a>
                          </li>
                          <li class="mt-[10px]">
                            <a
                              href=""
                              class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                            >
                              <i class="mdi mdi-chevron-right"></i> ss gates{" "}
                            </a>
                          </li>
                          <li class="mt-[10px]">
                            <a
                              href=""
                              class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                            >
                              <i class="mdi mdi-chevron-right"></i> Oil
                              paintings{" "}
                            </a>
                          </li>
                          <li class="mt-[10px]">
                            <a
                              href=""
                              class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                            >
                              <i class="mdi mdi-chevron-right"></i>photography
                              service{" "}
                            </a>
                          </li>
                          <li class="mt-[10px]">
                            <a
                              href=""
                              class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                            >
                              <i class="mdi mdi-chevron-right"></i> Old photo
                              restoration{" "}
                            </a>
                          </li>
                          <li class="mt-[10px]">
                            <a
                              href=""
                              class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                            >
                              <i class="mdi mdi-chevron-right"></i> Photo
                              laminations{" "}
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div class="">
                        <ul class="list-none footer-list mt-6">
                          <li>
                            <a
                              href=""
                              class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                            >
                              <i class="mdi mdi-chevron-right"></i> My account{" "}
                            </a>
                          </li>
                          <li class="mt-[10px]">
                            <a
                              href=""
                              class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                            >
                              <i class="mdi mdi-chevron-right"></i> Order
                              History{" "}
                            </a>
                          </li>
                          <li class="mt-[10px]">
                            <a
                              href=""
                              class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                            >
                              <i class="mdi mdi-chevron-right"></i> Wish List{" "}
                            </a>
                          </li>
                          <li class="mt-[10px]">
                            <a
                              href=""
                              class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                            >
                              <i class="mdi mdi-chevron-right"></i> Newsletter
                            </a>
                          </li>
                          <li class="mt-[10px]">
                            <a
                              href=""
                              class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                            >
                              <i class="mdi mdi-chevron-right"></i> Affiliate
                            </a>
                          </li>
                          <li class="mt-[10px]">
                            <a
                              href=""
                              class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                            >
                              <i class="mdi mdi-chevron-right"></i> Returns
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div class="">
                        <ul class="list-none footer-list mt-6">
                          <li>
                            <a
                              href=""
                              class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                            >
                              <i class="mdi mdi-chevron-right"></i>
                              <Link to={"/TermsandConditions"}>
                                Terms and Conditions
                              </Link>{" "}
                            </a>
                          </li>
                          <li class="mt-[10px]">
                            <a
                              href=""
                              class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                            >
                              <i class="mdi mdi-chevron-right"></i>
                              <Link to={"/PrivacyPolicy"}>
                                Privacy Policy
                              </Link>{" "}
                            </a>
                          </li>
                          <li class="mt-[10px]">
                            <a
                              href=""
                              class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                            >
                              <i class="mdi mdi-chevron-right"></i>
                              <Link to={"/ShippingPolicy"}>
                                Shipping Policy
                              </Link>{" "}
                            </a>
                          </li>
                          <li class="mt-[10px]">
                            <a
                              href=""
                              class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                            >
                              <i class="mdi mdi-chevron-right"></i>
                              <Link to={"/contact_support"}>Contact Us</Link>
                            </a>
                          </li>
                          <li class="mt-[10px]">
                            <a
                              href=""
                              class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                            >
                              <i class="mdi mdi-chevron-right"></i>
                              <Link to={"/CancellationandRefunds"}>
                                Cancellation and Refunds
                              </Link>
                            </a>
                          </li>
                          <li class="mt-[10px]">
                            <a
                              href=""
                              class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                            >
                              <i class="mdi mdi-chevron-right"></i>{" "}
                              <Link to={"/AboutUs"}>About us</Link>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="grid grid-cols-1">
            <div class="py-[30px] px-0 border-t border-slate-800">
              <div class="grid lg:grid-cols-4 md:grid-cols-2">
                <div class="flex items-center lg:justify-center">
                  <i class="mdi mdi-truck-check-outline align-middle text-lg mb-0 me-2"></i>
                  <h6 class="mb-0 font-medium">Free delivery</h6>
                </div>

                <div class="flex items-center lg:justify-center">
                  <i class="mdi mdi-archive align-middle text-lg mb-0 me-2"></i>
                  <h6 class="mb-0 font-medium">Non-contact shipping</h6>
                </div>

                <div class="flex items-center lg:justify-center">
                  <i class="mdi mdi-cash-multiple align-middle text-lg mb-0 me-2"></i>
                  <h6 class="mb-0 font-medium">Money-back quarantee</h6>
                </div>
                <div class="flex items-center lg:justify-center">
                  <i class="mdi mdi-shield-check align-middle text-lg mb-0 me-2"></i>
                  <h6 class="mb-0 font-medium">Secure payments</h6>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="py-[30px] px-0 border-t border-slate-800">
          <div class="container relative text-center">
            <div class="grid md:grid-cols-2 items-center">
              <div class="md:text-start text-center">
                <p class="mb-0">
                  © {currentYear} planet 56{" "}
                  <i class="mdi mdi-heart text-red-600"></i>{" "}
                  <a
                    href="https://planet56.com/"
                    target="_blank"
                    class="text-reset"
                  >
                    Serving memories since 1987
                  </a>
                </p>
              </div>

              <ul class="list-none md:text-end text-center mt-6 md:mt-0">
                <li class="inline">
                  <a href="">
                    <img
                      src="assets/images/payments/american-express.jpg"
                      class="max-h-6 rounded inline"
                      title="American Express"
                      alt=""
                    />
                  </a>
                </li>
                <li class="inline">
                  <a href="">
                    <img
                      src="assets/images/payments/discover.jpg"
                      class="max-h-6 rounded inline"
                      title="Discover"
                      alt=""
                    />
                  </a>
                </li>
                <li class="inline">
                  <a href="">
                    <img
                      src="assets/images/payments/mastercard.jpg"
                      class="max-h-6 rounded inline"
                      title="Master Card"
                      alt=""
                    />
                  </a>
                </li>
                <li class="inline">
                  <a href="">
                    <img
                      src="assets/images/payments/paypal.jpg"
                      class="max-h-6 rounded inline"
                      title="Paypal"
                      alt=""
                    />
                  </a>
                </li>
                <li class="inline">
                  <a href="">
                    <img
                      src="assets/images/payments/visa.jpg"
                      class="max-h-6 rounded inline"
                      title="Visa"
                      alt=""
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
