import React, { useEffect, useState } from "react";
import { MdModeEdit } from "react-icons/md";
import { makePostRequest, makeGetRequest } from "../../../Api/requestMethods";
import { personalDetails, userDetails } from "../../../Api/api";
import { GrRevert } from "react-icons/gr";
import { useAuth } from "../../../contexts/auth";
import { useQuery } from "@tanstack/react-query";
import { personalDetails_validation } from "../../../schemas/Validations";
import { personalDetails_form } from "../../../schemas/Forms";
import { success, somethingWentWrong } from "../../../global/constants";
import { valueZero, valueOne } from "../../../global/constants";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./accountDetails.css";

const AccountDetails = () => {
  const { user } = useAuth();
  const [settings, setSettings] = useState({
    isEditEnabled: false,
  });
  const [loading, setLoading] = useState(true);
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: personalDetails_form,
    validationSchema: personalDetails_validation,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await updatePersonalDetails(values);
      setSubmitting(false);
    },
  });

  const fetchAccountDetails = async () =>
    await makeGetRequest(userDetails(user?.id));
  const { data: accountDetailsData } = useQuery({
    queryKey: ["__accountDetails__"],
    queryFn: () => fetchAccountDetails(),
    enabled: !!user?.id,
  });

  useEffect(() => {
    if (accountDetailsData) {
      const accountData =
        accountDetailsData?.data && accountDetailsData?.data[0];
      setValues(accountData);
    }
    setFieldValue("id", user?.id);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [accountDetailsData]);

  const updatePersonalDetails = async (values) => {
    const response = await makePostRequest(personalDetails, values);
    if (response && response.status === success) {
      toast.success(response.message);
    } else {
      toast.error(somethingWentWrong);
    }
  };

  const handleEdit = () => {
    setSettings((prev) => ({ ...prev, isEditEnabled: !prev.isEditEnabled }));
  };
  return (
    <>
      <div className="row d-flex flex-wrap align-baseline pt-3 justify-content-between">
        <p className="col-6 text-2xl">Personal Information</p>
        <div className="col-4 mt-2 ms-5 me-5" onClick={handleEdit}>
          {settings.isEditEnabled ? (
            <GrRevert className="text-2xl" color="#8cc33f" />
          ) : (
            <MdModeEdit className="text-2xl" color="#8cc33f" />
          )}
        </div>
      </div>

      <div className="row">
        <div className="mt-5 col-lg-4">
          <label
            htmlFor="name"
            className="block font-bold text-gray-600 text-xs"
          >
            First Name
          </label>
          {loading ? (
            <Skeleton height={40} />
          ) : (
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Enter your First-name."
              class="py-3 px-4 block w-full border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
              value={values.user_name}
              onChange={(e) =>
                handleChange({
                  target: { name: "user_name", value: e.target.value },
                })
              }
              onBlur={(e) =>
                handleBlur({
                  target: { name: "user_name", value: e.target.value },
                })
              }
              disabled={!settings.isEditEnabled}
            />
            
            
          )}
          {touched.user_name && errors.user_name ? (
            <div className="fieldRequiredError text-red-500 text-sm">
              {errors.user_name}
            </div>
          ) : null}
        </div>

        <div className="mt-5 col-lg-4">
          <label
            htmlFor="last_name"
            className="block font-bold text-gray-600 text-xs"
          >
            Last Name
          </label>
          {loading ? (
            <Skeleton height={40} />
          ) : (
            <input
              type="text"
              id="last_name"
              name="last_name"
              placeholder="Enter your Last Name"
              class="py-3 px-4 block w-full border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
              value={values.last_name}
              onChange={(e) =>
                handleChange({
                  target: { name: "last_name", value: e.target.value },
                })
              }
              onBlur={(e) =>
                handleBlur({
                  target: { name: "last_name", value: e.target.value },
                })
              }
              disabled={!settings.isEditEnabled}
            />
          )}
          {touched.last_name && errors.last_name ? (
            <div className="fieldRequiredError text-red-500 text-sm">
              {errors.last_name}
            </div>
          ) : null}
        </div>

        <div className="row mt-3 w-100">
          <fieldset
            onChange={(e) =>
              handleChange({
                target: { name: "gender", value: e.target.value },
              })
            }
          >
            <label
              htmlFor="gender"
              className="block font-bold text-gray-600 text-xs mb-1"
            >
              Your Gender
            </label>
            <div className="d-flex">
              {loading ? (
                <div className="d-flex">
                  <Skeleton width={80} height={20} />
                  <Skeleton width={80} height={20} className="ms-4" />
                </div>
              ) : (
                <>
                  <div className="group me-3">
                    <input
                      type="radio"
                      id="radio01"
                      name="gender"
                      value={valueOne}
                      checked={values.gender === valueOne}
                      disabled={!settings.isEditEnabled}
                    />
                    <label className="ms-1" htmlFor="radio01">
                      Male
                    </label>
                  </div>
                  <div className="group">
                    <input
                      type="radio"
                      id="radio02"
                      name="gender"
                      value={valueZero}
                      checked={values.gender === valueZero}
                      disabled={!settings.isEditEnabled}
                    />
                    <label className="ms-1" htmlFor="radio02">
                      Female
                    </label>
                  </div>
                </>
              )}
            </div>
          </fieldset>
        </div>
      </div>

      <div className="mt-4">
        <button
          className="btn brandColorBTN d-flex flex-wrap py-2 px-5"
          onClick={handleSubmit}
          disabled={!settings.isEditEnabled || isSubmitting}
        >
          <span className="text-white">Save</span>
        </button>
      </div>
    </>
  );
};

export default AccountDetails;
