import React from 'react';
import { createRoot } from "react-dom/client";
import App from './App'
import './index.css';
import "./dist/assets/libs/tobii/css/tobii.min.css";
import "./dist/assets/libs/@mdi/font/css/materialdesignicons.min.css";
import "./dist/assets/css/tailwind.css";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";



const queryClient = new QueryClient({});
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(<QueryClientProvider client={queryClient}>
  <App />
  <ToastContainer position="bottom-center" theme="dark" hideProgressBar/>
</QueryClientProvider>);
