import React, { createContext, useContext, useState } from "react";

const GlobalContext = createContext();

export function GlobalDataProvider({ children }) {
  const [globalSettings, globalSetSettings] = useState({
    whishlistPopup: false,
    whislistDeleteTrigger: 0,
  });
  const [globalDs, setGlobalDs] = useState({
    otpEnabled: false,
    wishlistDataSource: "",
    userDetails: "",
    personalDetails: "",
    cartCount: 0,
    categoryDataSource: "",
    isWishlistLoading: false,
    isLoginEnabled: false,
  });


  

  return (
    <GlobalContext.Provider
      value={{
        globalDs,
        setGlobalDs,
        globalSettings,
        globalSetSettings,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}

export function useGlobalProvider() {
  return useContext(GlobalContext);
}
