import React, { useState } from "react";
import { adminProductsImagePath } from "../../../config/config";
import "../productDetailedView.css";

const ImageGallery = ({ productImages }) => {
  const [currentImage, setCurrentImage] = useState(productImages[0]);
  const [magnifierStyle, setMagnifierStyle] = useState({
    display: "none",
    top: 0,
    left: 0,
    backgroundPosition: "0% 0%",
    backgroundSize: "200%",
  });

  const onMouseEnterEvent = (url) => {
    setCurrentImage(url);
    setMagnifierStyle((prevStyle) => ({
      ...prevStyle,
      backgroundImage: `url(${adminProductsImagePath}${url})`,
    }));
  };

  const handleMouseMove = (e) => {
    const img = e.currentTarget.querySelector("img");
    const imgPosition = img.getBoundingClientRect();
    const posX = e.pageX - imgPosition.left - window.scrollX;
    const posY = e.pageY - imgPosition.top - window.scrollY;
    const imgHeight = imgPosition.height;
    const imgWidth = imgPosition.width;
    const percX = (posX / imgWidth) * 100;
    const percY = (posY / imgHeight) * 100;

    setMagnifierStyle({
      display: "block",
      top: posY,
      left: posX,
      backgroundPosition: `${percX}% ${percY}%`,
      backgroundSize: "400%",
      backgroundImage: `url(${adminProductsImagePath}${currentImage})`,
    });
  };

  const handleMouseLeave = () => {
    setMagnifierStyle((prevStyle) => ({
      ...prevStyle,
      display: "none",
    }));
  };

  return (
    <div className="flex items-start">
      <div className="thumbnails flex flex-col justify-between mr-4" style={{ height: '500px' }}>
        {productImages.map((url, index) => (
          <div
            key={index}
            className="thumbnail py-2 cursor-pointer flex-1 px-1"
          >
            <img
              src={`${adminProductsImagePath}${url}`}
              className="object-cover w-full h-full shadow dark:shadow-gray-800"
              alt={`Product thumbnail ${index + 1}`}
              onMouseEnter={() => onMouseEnterEvent(url)}
            />
          </div>
        ))}
      </div>
      <div
        className="magnified"
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        style={{ width: '500px', height: '500px' }}
      >
        <img
          src={`${adminProductsImagePath}${currentImage}`}
          alt="Current Product Image"
          className="object-cover w-full h-full"
        />
        <div
          className="magnifier"
          style={magnifierStyle} 
        ></div>
      </div>
    </div>
  );
};

export default ImageGallery;
