import React from "react";
import { isValidArrayWithData } from "../../utils/reUsableFun";

const SelectBox = ({
  dataSource = [],
  displayExpr,
  valueExpr,
  onValueChanged,
  placeholder = "Select an option",
  value,
  onFocusOut,
  onChange,
}) => {
  const handleChange = (event) => {
    const selectedValue = event.target.value;
    if (onValueChanged) {
      onValueChanged(selectedValue);
    }
    if (onChange) {
      onChange(event);
    }
  };

  const handleBlur = (event) => {
    const selectedValue = event.target.value;
    if (onFocusOut) {
      onFocusOut(selectedValue);
    }
  };

  return (
    <select
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      className="py-3 px-4 block w-full border border-gray-200 rounded-lg text-sm font-light focus:border-[#8cc33f] focus:ring-[#8cc33f] disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-300 dark:focus:ring-[#8cc33f]"
    >
      <option value="">{placeholder}</option>
      {isValidArrayWithData(dataSource) && dataSource.map((item, index) => (
        <option key={index} value={item[valueExpr]}>
          {item[displayExpr]}
        </option>
      ))}
    </select>
  );
};

export default SelectBox;
