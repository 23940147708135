import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider, useAuth } from "./contexts/auth";
import Header from "./layout/header";
import Footer from "./layout/footer";
import {
  Home,
  About,
  Contact,
  CreateAccount,
  ProductDetails,
  ProductDetailedView,
  ForgotPassword,
  ResetForm,
  ConfirmEmail,
  Account,
  Wishlist,
  Orders,
  AccountDetails,
  AddressDetails,
  Cart,
  CheckOut,
  LoginModal,
} from "./pages";
import { GlobalDataProvider } from "./global/provider";
import {
  HelpCenter,
  ContactSupport,
  Faq,
  AboutUs,
  TermsandConditions,
  ShippingPolicy,
  CancellationandRefunds,
  PrivacyPolicy,
  PaymentFailed,
  PaymentSuccess,
} from "./components";
import WhatsApp from "./components/whatsapp/whatsApp";
import PrivateRoute from "./utils/PrivateRoute";
import "./App.css";
function App() {
  return (
    <>
      <Router>
        <GlobalDataProvider>
          <AuthProvider>
            <Header />
            <LoginModal />
            <WhatsApp />

            <Routes>
              {/* <Route path="/login" element={<Login />} /> */}
              <Route path="*" element={<> Page not found</>} />

              {/* Auth Routes */}
              {/* <Route path="/login" element={<Login />} /> */}
              <Route path="/forgotPassword" element={<ForgotPassword />} />
              <Route path="/resetPassword" element={<ResetForm />} />
              <Route path="/createAccount" element={<CreateAccount />} />
              <Route path="/confirmEmail" element={<ConfirmEmail />} />

              <Route path="/payment-failed" element={<PaymentFailed />} />

              <Route path="/payment-success" element={<PaymentFailed />} />

              {/* Account */}
              <Route
                path="/account"
                element={
                  <PrivateRoute>
                    <Account />
                  </PrivateRoute>
                }
              >
                <Route
                  path="wishlist"
                  element={
                    <PrivateRoute>
                      <Wishlist />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="orders"
                  element={
                    <PrivateRoute>
                      <Orders />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="accountDetails"
                  element={
                    <PrivateRoute>
                      <AccountDetails />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="addressDetails"
                  element={
                    <PrivateRoute>
                      <AddressDetails />
                    </PrivateRoute>
                  }
                />
              </Route>
              <Route path="cart" element={<Cart />} />
              <Route
                path="checkout"
                element={
                  <PrivateRoute>
                    <CheckOut />
                  </PrivateRoute>
                }
              />

              {/* Pages Routes */}
              <Route path="/productDetails" element={<ProductDetails />} />
              <Route
                path="/ProductDetailedView"
                element={<ProductDetailedView />}
              />

              <Route path="/contact" element={<Contact />} />
              <Route path="/" element={<Home />} />
              <Route path="about_me" element={<About />} />
              <Route path="/help_center" element={<HelpCenter />} />
              <Route path="/contact_support" element={<ContactSupport />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/aboutUs" element={<AboutUs />} />
              <Route
                path="/TermsandConditions"
                element={<TermsandConditions />}
              />
              <Route path="/ShippingPolicy" element={<ShippingPolicy />} />
              <Route
                path="/CancellationandRefunds"
                element={<CancellationandRefunds />}
              />
              <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
            </Routes>

            <Footer />
          </AuthProvider>
        </GlobalDataProvider>
      </Router>
    </>
  );
}

export default App;
