import React, { useEffect, useState } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import { somethingWentWrong, statusSuccess } from "../../global/constants";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  makeDeleteRequest,
  makeGetRequest,
  makePutRequest,
  makePostRequest,
} from "../../Api/requestMethods";
import {
  addToCart,
  getCartList,
  updateCartDetails,
  deleteCartDetails,
} from "../../Api/api";
import { useAuth } from "../../contexts/auth";
import { getSessionId, isValidArrayWithData } from "../../utils/reUsableFun";
import { useQuery, useMutation } from "@tanstack/react-query";
import { IndianRupeeSymbol } from "../../global/constants";
import { toast } from "react-toastify";
import { getCartTotal } from "../../utils/reUsableFun";
import CartEmpty from "./cartEmpty";
import { useGlobalProvider } from "../../global/provider";
import { useNavigate } from "react-router-dom";
import { adminProductsImagePath } from "../../config/config";

const Cart = () => {
  const navigate = useNavigate();
  const [cartDetailsData, setCartDetails] = useState({
    cartTotalDetails: "",
    cartTrigger: 0,
  });
  const { globalDs, setGlobalDs } = useGlobalProvider();
  const { user } = useAuth();

  const CartFetch = async () =>
    await makeGetRequest(
      getCartList((user && user?.id) || null, getSessionId() || "")
    );
  const { data: cartDetails, isLoading } = useQuery({
    queryKey: [
      "__getCartList__",
      user && user?.id,
      getSessionId(),
      cartDetailsData.cartTrigger,
    ],
    retry: false,
    queryFn: () => CartFetch(),
    enabled: !!user?.id || !!getSessionId(),
  });

  useEffect(() => {setCartDetails(prev => ({...prev, cartTrigger : prev.cartTrigger + 1 }))},[])

  useEffect(() => {
    if (
      cartDetails &&
      cartDetails?.data &&
      isValidArrayWithData(cartDetails?.data)
    ) {
      const cartTotal = getCartTotal(cartDetails?.data);
      setCartDetails((prev) => ({
        ...prev,
        cartTotalDetails: cartTotal,
      }));
      setGlobalDs((prev) => ({
        ...prev,
        cartCount: cartDetails?.data?.length || 0,
      }));
    } else {
      setGlobalDs((prev) => ({
        ...prev,
        cartCount: 0,
      }));
    }
  }, [cartDetails]);

  const onDeleteCartItem = async (data) => {
    try {
      const response = await makeDeleteRequest(
        deleteCartDetails(
          (user && user?.id) || null,
          getSessionId() || "",
          data.products_id
        )
      );
      if (response && response.status === statusSuccess) {
        toast.success(response.message);
        setCartDetails((prev) => ({
          ...prev,
          cartTrigger: prev.cartTrigger + 1,
        }));
      } else {
        setCartDetails((prev) => ({
          ...prev,
          cartTrigger: prev.cartTrigger + 1,
        }));
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(somethingWentWrong);
      console.error(error);
    }
  };

  const updateCartCount = async (qty, data) => {
    try {
      const response = await makePutRequest(
        updateCartDetails(
          (user && user?.id) || null,
          getSessionId() || "",
          qty,
          data.products_id
        )
      );
      if (response && response.status === statusSuccess) {
        toast.success(response.message);
        setCartDetails((prev) => ({
          ...prev,
          cartTrigger: prev.cartTrigger + 1,
        }));
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(somethingWentWrong);
    }
  };

  const UpdateventHandler = (isIncrement, qty, data) => {
    try {
      if (isIncrement) {
        updateCartCount(qty + 1, data);
      } else {
        if (qty > 0) {
          if (qty !== 1) updateCartCount(qty !== 1 ? qty - 1 : qty, data);
          return;
        }
      }
    } catch (error) {
      toast.error(somethingWentWrong);
      console.error(error);
    }
  };

  const renderSkeletonRows = () => {
    return Array(5)
      .fill()
      .map((_, idx) => (
        <tr key={idx}>
          <td className="p-4">
            <Skeleton width={30} height={30} />
          </td>
          <td className="p-4">
            <Skeleton height={30} width={200} />
          </td>
          <td className="p-4 text-center">
            <Skeleton height={30} width={80} />
          </td>
          <td className="p-4 text-center">
            <Skeleton height={30} width={120} />
          </td>
          <td className="p-4 text-end">
            <Skeleton height={30} width={80} />
          </td>
        </tr>
      ));
  };

  console.log(cartDetails,'cartDetails');
  

  return (
    <>
      {(globalDs && globalDs?.cartCount === 0 && !isLoading) || !cartDetails ? (
        <CartEmpty />
      ) : (
        <section class="relative md:py-24 py-16">
          <div class="container relative">
            <div class="grid lg:grid-cols-1 mt-5 mt-lg-2">
              <div class="relative overflow-x-auto shadow dark:shadow-gray-800 rounded-md">
                <table class="w-full text-start">
                  <thead class="text-sm uppercase bg-slate-50 dark:bg-slate-800">
                    <tr>
                      <th scope="col" class="p-4 w-4"></th>
                      <th scope="col" class="text-start p-4 min-w-[220px]">
                        Product
                      </th>
                      <th scope="col" class="p-4 w-24 min-w-[100px]">
                        Price
                      </th>
                      <th scope="col" class="p-4 w-56 min-w-[220px]">
                        Qty
                      </th>
                      <th scope="col" class="p-4 w-24 min-w-[100px]">
                        Total($)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading
                      ? renderSkeletonRows()
                      : cartDetails?.data &&
                        isValidArrayWithData(cartDetails?.data) &&
                        cartDetails.data.map((data, index) => (
                          <tr class="bg-white dark:bg-slate-900">
                            <td
                              class="p-4"
                              onClick={() => onDeleteCartItem(data)}
                            >
                              <button className="me-4 size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center bg-red-600/5 text-red-600  rounded-full">
                               <RiDeleteBinLine className="icon" />
                              </button>
                            </td>
                            <td class="p-4">
                              <span class="flex items-center">
                                <img
                                  src={`${adminProductsImagePath}${data?.product_img}`}
                                  class="rounded shadow dark:shadow-gray-800 w-12"
                                  alt=""
                                />
                                <span class="ms-3">
                                  <span class="block font-semibold">
                                    {data?.product_name} - {data?.attributeType}
                                  </span>
                                </span>
                              </span>
                            </td>
                            <td class="p-4 text-center">
                              {IndianRupeeSymbol} {data?.real_price}
                            </td>
                            <td class="p-4 text-center">
                              <div class="qty-icons">
                                <button
                                  onClick={() =>
                                    UpdateventHandler(
                                      false,
                                      data?.quantity,
                                      data
                                    )
                                  }
                                  class="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white minus"
                                >
                                  -
                                </button>
                                <input
                                  min={0}
                                  name="quantity"
                                  type="number"
                                  class="h-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white pointer-events-none w-16 ps-4 quantity"
                                  value={data?.quantity}
                                />
                                <button
                                  onClick={() =>
                                    UpdateventHandler(
                                      true,
                                      data?.quantity,
                                      data
                                    )
                                  }
                                  class="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white plus"
                                >
                                  +
                                </button>
                              </div>
                            </td>
                            <td class="p-4  text-end">
                              {IndianRupeeSymbol}{" "}
                              {data?.real_price * data?.quantity}
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>

              <div class="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
                <div class="lg:col-span-9 md:order-1 order-3">
                  <div class="space-x-1">
                    <button
                      onClick={() => navigate("/checkout")}
                      class="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-base text-center bg-orange-500 text-white rounded-md mt-2"
                    >
                      Proceed to checkout
                    </button>
                  </div>
                </div>

                <div class="lg:col-span-3 md:order-2 order-1">
                  <ul class="list-none shadow dark:shadow-gray-800 rounded-md">
                    <li class="flex justify-between p-4">
                      <span class="font-semibold text-lg">Subtotal :</span>
                      <span class="text-slate-400">
                        {IndianRupeeSymbol}{" "}
                        {cartDetailsData?.cartTotalDetails?.subtotal}
                      </span>
                    </li>
                    <li class="flex justify-between p-4 border-t border-gray-100 dark:border-gray-800">
                      <span class="font-semibold text-lg">Discounts :</span>
                      <span class="text-slate-400">
                        {IndianRupeeSymbol}{" "}
                        {cartDetailsData?.cartTotalDetails?.discount}
                      </span>
                    </li>
                    <li class="flex justify-between font-semibold p-4 border-t border-gray-200 dark:border-gray-600">
                      <span class="font-semibold text-lg">Total :</span>
                      <span class="font-semibold">
                        {IndianRupeeSymbol}{" "}
                        {cartDetailsData?.cartTotalDetails?.total}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Cart;
