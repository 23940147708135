import { makePostRequest } from "../Api/requestMethods";
import { v4 as uuidv4 } from "uuid";

const postFetch = async (api, data) => {
  try {
    const response = await makePostRequest(api, data);
    return response;
  } catch (error) {
    throw error;
  }
};

// To get the Random images from the array
const getRandomImagesFromArray = (arr, count) => {
  try {
    let shuffled = arr.slice(0),
      i = arr.length,
      min = i - count,
      temp,
      index;
    while (i-- > min) {
      index = Math.floor((i + 1) * Math.random());
      temp = shuffled[index];
      shuffled[index] = shuffled[i];
      shuffled[i] = temp;
    }
    return shuffled.slice(min);
  } catch (error) {
    console.error();
  }
};

const onChangeNumberOnly = (value) => {
  return /^\d*$/.test(value);
};

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

function isValidArrayWithData(arr) {
  try {
    if (Array.isArray(arr) && arr.length > 0) {
      return true;
    }
    return false;
  } catch (error) {
    console.error(error);
  }
}

const isValidValue = (value) => {
  try {
    if (value && value !== "" && value !== null) return true;
    return false;
  } catch (error) {
    console.error(error);
  }
};

const sortArrayByProperty = (array, property, sortType, value) => {
  try {
    switch (sortType) {
      case "str_asc":
        return array.sort((a, b) => a[property].localeCompare(b[property]));

      case "str_des":
        return array.sort((a, b) => b[property].localeCompare(a[property]));

      case "num_asc":
        return array.sort((a, b) => a[property] - b[property]);

      case "num_des":
        return array.sort((a, b) => b[property] - a[property]);

      default:
        const filterData = array.filter(
          (data) => data.discount_highlights === value
        );
        return filterData;
    }
  } catch (error) {
    console.errog(error);
  }
};

function formatDateToDateAndTime(dateString) {
  try {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    const ordinalSuffix = (d) => {
      if (d > 3 && d < 21) return "th";
      switch (d % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const formattedDate = `${day}${ordinalSuffix(
      day
    )} ${month} ${year} at ${hours}:${minutes} ${ampm}`;
    return formattedDate;
  } catch (error) {
    console.error("Invalid date format", error);
    return "Invalid date";
  }
}

const SessionExistsOrCreate = () => {
  try {
    var uniqueId;
    uniqueId = localStorage.getItem("p56_s_");
    if (!uniqueId) {
      uniqueId = uuidv4();
      localStorage.setItem("p56_s_", uniqueId);
      return uniqueId;
    }
    return uniqueId;
  } catch (error) {
    console.error(error);
  }
};

const getSessionId = () => {
  try {
    const getSessionid = localStorage.getItem("p56_s_");
    return getSessionid ? getSessionid : "";
  } catch (error) {
    console.error(error);
  }
};

// "2024-11-30T10:37:06.000Z" to "30th November 2024"
function formatDatetochar(dateString) {

  try {
    if(!isValidValue(dateString))return
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    const suffix =
      day % 10 === 1 && day !== 11
        ? "st"
        : day % 10 === 2 && day !== 12
        ? "nd"
        : day % 10 === 3 && day !== 13
        ? "rd"
        : "th";

    return `${day}${suffix} ${month} ${year}`;
  } catch (error) {console.error(error)}
}

const getCartTotal = (cartItems) => {
  try {
    if (Array.isArray(cartItems) && cartItems.length > 0) {
      let subtotal = 0;
      let discount = 0;
      cartItems.forEach((item) => {
        const realPrice = parseFloat(item.real_price);
        const discount_price = parseFloat(item.discount_price);
        const quantity = item.quantity;
        subtotal += discount_price * quantity;
        discount += (discount_price - realPrice) * quantity;
      });
      const total = subtotal - discount;
      return { subtotal, discount, total };
    } else {
      return { subtotal: 0, discount: 0, total: 0 };
    }
  } catch (error) {
    console.error("Error calculating cart total:", error);
    return { subtotal: 0, discount: 0, total: 0 };
  }
};

function isValidObject(value) {
  return value !== null && typeof value === "object" && !Array.isArray(value);
}

export {
  getRandomImagesFromArray,
  postFetch,
  onChangeNumberOnly,
  isValidArrayWithData,
  sortArrayByProperty,
  isValidValue,
  formatDateToDateAndTime,
  SessionExistsOrCreate,
  getSessionId,
  getCartTotal,
  isValidObject,
  formatDatetochar
};
