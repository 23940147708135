import React, { useState, useEffect, useRef } from "react";
import { makeGetRequest, makePostRequest } from "../../Api/requestMethods";
import { getSessionId, isValidValue } from "../../utils/reUsableFun";
import { getCartList } from "../../Api/api";
import { useAuth } from "../../contexts/auth";
import { useQuery } from "@tanstack/react-query";
import { getCartTotal } from "../../utils/reUsableFun";
import { isValidArrayWithData } from "../../utils/reUsableFun";
import { IndianRupeeSymbol } from "../../global/constants";
import Razorpay from "../../components/payment/razorPay";
import AddressDetails from "../account/components/addressDetails";
import { toast } from "react-toastify";
import { isValidObject } from "../../utils/reUsableFun";
import { tempOrder, createOrder } from "../../Api/api";
import { useGlobalProvider } from "../../global/provider";

const CheckOut = () => {
  const razorpayRef = useRef(null);
  const {globalDs} = useGlobalProvider()

  const [cartDetailsData, setCartDetails] = useState({
    cartTotalDetails: "",
    cartTrigger: 0,
    cartDataSource: "",
    addressDetails: "",
  });
  const { user } = useAuth();
  const CartFetch = async () =>
    await makeGetRequest(
      getCartList((user && user?.id) || null, getSessionId() || "")
    );
  const { data: cartDetails } = useQuery({
    queryKey: ["__getCartList__", user && user?.id, getSessionId()],
    queryFn: () => CartFetch(),
    enabled: !!user?.id || !!getSessionId(),
  });

  useEffect(() => {
    if (
      cartDetails &&
      cartDetails?.data &&
      isValidArrayWithData(cartDetails?.data)
    ) {
      const cartTotal = getCartTotal(cartDetails?.data);
      setCartDetails((prev) => ({
        ...prev,
        cartTotalDetails: cartTotal,
        cartDataSource: cartDetails?.data,
      }));
    }
  }, [cartDetails]);



  const updateAddressCb = (data) => {
    setCartDetails((prev) => ({ ...prev, addressDetails: data }));
  };

  const handleSubmit = async () => {
    try {
      if (!cartDetailsData.addressDetails) {
        toast.error("Please fill the address details");
        return;
      }
      const body = {
        addressDetails: {
          ...cartDetailsData.addressDetails,
          email: globalDs?.personalDetails?.email || "",
        },
        
        cartTotalDetails : cartDetailsData.cartTotalDetails
      }
      const response = await makePostRequest(tempOrder,body)
      if(response.status === 200){
        razorpayRef.current.triggerPayment(response.data, body, cartDetailsData.cartDataSource);
  
      }
    } catch (error) {
      console.error(error)
      
    }


  };

  const addressDeleteCb = (id) => {
    try {
      if (
        isValidObject(cartDetailsData.addressDetails) &&
        cartDetailsData.addressDetails.account_id === id
      ) {
        setCartDetails((prev) => ({ ...prev, addressDetails: "" }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <section class="relative md:py-24 py-16">
      <div class="container relative">
        <div class="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 gap-6">
          <div class="lg:col-span-8">
            <div class="p-6 rounded-md shadow dark:shadow-gray-800">
              <h3 class="text-xl leading-normal font-semibold">
                Billing address
              </h3>

              <div>
                <AddressDetails
                  isCheckout={true}
                  updateAddressCb={updateAddressCb}
                  addressDeleteCb={addressDeleteCb}
                  setCartDetails={setCartDetails}
                />
              </div>

              {isValidValue(cartDetailsData.addressDetails) && (
                <div class="mt-4">
                  <button
                    class="py-2 px-5 inline-block tracking-wide align-middle duration-500 text-base text-center bg-orange-500 text-white rounded-md w-full"
                    onClick={handleSubmit}
                  >
                    Checkout
                  </button>
                </div>
              )}
            </div>
          </div>

          <div class="lg:col-span-4">
            <div class="p-6 rounded-md shadow dark:shadow-gray-800">
              <div class="flex justify-between items-center">
                <h5 class="text-lg font-semibold">Your Cart</h5>

                <p class="bg-orange-500 flex justify-center items-center text-white text-[10px] font-bold px-2.5 py-0.5 rounded-full h-5">
                  {cartDetailsData &&
                    cartDetailsData?.cartDataSource &&
                    isValidArrayWithData(cartDetailsData.cartDataSource) &&
                    cartDetailsData?.cartDataSource?.length}
                </p>
              </div>

              <div class="mt-4 rounded-md shadow dark:shadow-gray-800">
                {cartDetailsData?.cartDataSource &&
                  isValidArrayWithData(cartDetailsData.cartDataSource) &&
                  cartDetailsData?.cartDataSource.map((data, index) => (
                    <div
                      class={`p-3 flex justify-between items-center ${
                        index !== 0
                          ? `border border-gray-100 dark:border-gray-800`
                          : ""
                      }`}
                    >
                      <div>
                        <h5 class="font-semibold">{data?.attributeType}</h5>
                        <p class="text-sm text-slate-400">
                          {IndianRupeeSymbol} {Number(data?.real_price)} *{" "}
                          {data?.quantity}
                        </p>
                      </div>

                      <p class="text-slate-400 font-semibold">
                        {IndianRupeeSymbol}{" "}
                        {Number(data?.real_price) * data?.quantity}
                      </p>
                    </div>
                  ))}

                <div class="p-3 flex justify-between items-center border border-gray-100 dark:border-gray-800 bg-gray-50 dark:bg-slate-800 text-green-600">
                  <div>
                    <h5 class="font-semibold">Discount</h5>
                  </div>

                  <p class="text-red-600 font-semibold">
                    -{IndianRupeeSymbol}{" "}
                    {cartDetailsData?.cartTotalDetails?.discount}
                  </p>
                </div>

                {/* <div class="p-3 flex justify-between items-center border border-gray-100 dark:border-gray-800 bg-gray-50 dark:bg-slate-800 text-green-600">
                  <div>
                    <h5 class="font-semibold">Promo code</h5>
                    <p class="text-sm text-green-600">EXAMPLECODE</p>
                  </div>

                  <p class="text-red-600 font-semibold">
                    -{IndianRupeeSymbol} 10
                  </p>
                </div> */}
                <div class="p-3 flex justify-between items-center border border-gray-100 dark:border-gray-800">
                  <div>
                    <h5 class="font-semibold">Sub total (INR)</h5>
                  </div>

                  <p class="font-semibold">
                    {IndianRupeeSymbol}{" "}
                    {cartDetailsData?.cartTotalDetails?.subtotal}
                  </p>
                </div>
                <div class="p-3 flex justify-between items-center border border-gray-100 dark:border-gray-800">
                  <div>
                    <h5 class="font-semibold">Total (INR)</h5>
                  </div>

                  <p class="font-semibold">
                    {IndianRupeeSymbol}{" "}
                    {cartDetailsData?.cartTotalDetails?.total}
                  </p>
                </div>
              </div>
              {/* 
              <div class="subcribe-form mt-6">
                <div class="relative max-w-xl">
                  <input
                    type="email"
                    id="subcribe"
                    name="email"
                    class="py-4 pe-40 ps-6 w-full h-[50px] outline-none text-black dark:text-white rounded-full bg-white dark:bg-slate-900 shadow dark:shadow-gray-800"
                    placeholder="Promo code"
                  />
                  <button
                    // type="submit"
                    class="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center absolute top-[2px] end-[3px] h-[46px] bg-orange-500 text-white rounded-full"
                  >
                    Redeem
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <Razorpay ref={razorpayRef} />
    </section>
  );
};

export default CheckOut;
