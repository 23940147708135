const accountSideMenu = [
  {
    id: 1,
    name: "Account",
    icon: "airplay",
    route: "AccountDetails",
  },
  {
    id: 10,
    name: "address",
    icon: "map-pin",
    route: "addressDetails",
  },
  {
    id: 2,
    name: "wishlist",
    icon: "heart",
    route: "wishlist",
  },
  {
    id: 9,
    name: "orders",
    icon: "truck",
    route: "orders",
  },

  // {
  //   id: 3,
  //   name: "Billing Info",
  //   icon: "edit",
  //   route: "/account",
  // },
  // {
  //   id: 7,
  //   name: "Settings",
  //   icon: "settings",
  //   route: "/account",
  // },
  {
    id: 8,
    name: "Sign Out",
    icon: "log-out",
    route: "/account",
  },
];

export { accountSideMenu };
