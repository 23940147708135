import React from "react";
import { adminProductsImagePath } from "../../../config/config";
import { useGlobalProvider } from "../../../global/provider";

const PopularItems = ({
  dataSource,
  productsDetailsNavigate,
  addToWishlisting,
}) => {
  const { globalDs } = useGlobalProvider();

  return (
    <>
      {dataSource?.data
        .filter((data) => data.is_popular_item === 1)
        .map((data, index) => {
          const images = JSON.parse(data.products_image)[0];
          const matchingWishlistItem =
            globalDs.wishlistDataSource &&
            globalDs.wishlistDataSource.length > 0 &&
            globalDs.wishlistDataSource.find(
              (wishlistItem) => wishlistItem.products_id === data.products_id
            );
          const isWishlistItem = !!matchingWishlistItem;
          return (
            <div
              key={index}
              className="group"
              onClick={() =>
                productsDetailsNavigate(data.products_id, data.category_id)
              }
            >
              <div className="relative overflow-hidden shadow dark:shadow-gray-800 group-hover:shadow-lg group-hover:dark:shadow-gray-800 rounded-md duration-500">
                <img
                  src={`${adminProductsImagePath}${images}`}
                  className="group-hover:scale-110 duration-500"
                  alt=""
                />
                <ul className="list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 duration-500 space-y-1">
                  <div
                    className={`size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full ${
                      isWishlistItem
                        ? "bg-green-500 text-white hover:bg-green-600"
                        : "bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      addToWishlisting(data, isWishlistItem);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-heart size-4"
                    >
                      <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
                    </svg>
                  </div>
                </ul>
                <ul className="list-none absolute top-[10px] start-4">
                  <li>
                    <span className="bg-red-600 text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5">
                      {data?.discount_percentage}% Off
                    </span>
                  </li>
                </ul>
              </div>

              <div className="mt-4">
                <span className="hover:text-orange-500 text-lg font-medium">
                  {data?.products_name}
                </span>
                <div className="flex justify-between items-center mt-1">
                  <p>
                    ₹{data?.real_price}{" "}
                    <del className="text-slate-400">₹{data?.fake_price}</del>
                  </p>
                  {/* <ul className="font-medium text-amber-400 list-none">
                    {[...Array(5)].map((_, starIndex) => (
                      <li key={starIndex} className="inline">
                        <i className="mdi mdi-star"></i>
                      </li>
                    ))}
                  </ul> */}
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default PopularItems;
