import planet56Logo from "../../assets/static/logo/logo_lightMode.png";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useImperativeHandle, forwardRef } from "react";
import { makePostRequest } from "../../Api/requestMethods";
import { createOrder } from "../../Api/api";

const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

const Razorpay = forwardRef((props, ref) => {
  const navigate = useNavigate();
  useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
  }, []);

  const handlePayment = async (paymentData, overallData, cartDetails) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    const options = {
      key: "rzp_test_7m6RdOWUcgcXIb",
      currency: "INR",
      name: "Planet56",
      amount: paymentData.amount.toString(),
      description:
        "Thank you for buying products in planet56. Have a great Day!",
      image: planet56Logo,
      prefill: {
        name: paymentData?.name,
        email: paymentData?.email || "info@planet56.in",
      },
      theme: {
        color: "#8cc33f",
      },
      order_id: paymentData?.order_id,
      handler: async function (response) {
        try {
          const body = {
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
            ...overallData,
            cartDetails : cartDetails

          }
      
          const orderResponse  = await  makePostRequest(createOrder, body)
    
          // if (response.data.success) {

          //   alert('Payment Successful!');
          //   window.location.href = '/order-confirmation';
          // } else {
          //   // Handle verification failure
          //   alert('Payment verification failed');
          //   console.error('Payment verification error', response.data);
          // }
        } catch (error) {
          
          // Handle API call or verification error
          console.error('Payment verification error', error);
          alert('An error occurred during payment verification');
        }
      }
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  useImperativeHandle(ref, () => ({
    triggerPayment: (paymentData, overallData, cartDetails) => handlePayment(paymentData, overallData, cartDetails),
  }));

  return <div />;
});

export default Razorpay;
