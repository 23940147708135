import React, { useState, useRef } from "react";
import { isValidValue } from "../../utils/reUsableFun";
import { FiUpload } from "react-icons/fi";
import "./imageUploader.css"

const ImageUploader = ({ onImageUpload }) => {
  const [imageBase64, setImageBase64] = useState("");
  const inputRef = useRef(null);

  const handleImageChange = (e) => {
    try {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result;
          setImageBase64(base64String);
          onImageUpload(base64String);
        };
        reader.readAsDataURL(file);
      }
    } catch (error) {}
  };

  const onButtonClick = () => {
    try {
      if (isValidValue(inputRef)) {
        inputRef.current.click();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="mt-2 col-12 d-flex wrappers">
        <button
          type="button"
          className="UploadBTN col-md-6 w-100"
          onClick={onButtonClick}
          style={{ cursor: "pointer", textAlign: "center" }}
        >  
          <FiUpload className="text-base"/>
          <p className="mx-auto text-center text-sm">
         
            Drag and drop or browse Image to upload
          </p>
          <input
            ref={inputRef}
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleImageChange}
          />
        </button>
        {imageBase64 && (
          <div className="preview ms-4 col-md-6">
            <img
              src={imageBase64}
              alt="Uploaded Preview"
              style={{ height: "120px" }}
              className="w-auto"
            />
          </div>
        )}
      </div>
    </>

    // <div className="mt-2 col-12 d-flex">
    //   <button type="button" className="UploadBTN col-md-6" onClick={onButtonClick}>
    //     <FiUpload className="UploadIcon me-1" /> Upload Image
    //     <input
    //       ref={inputRef}
    //       type="file"
    //       accept="image/*"
    //       style={{ display: "none" }}
    //       onChange={handleImageChange}
    //     />
    //   </button>
    //   {imageBase64 && (
    //     <div className="preview  col-md-6">
    //       <img
    //         src={imageBase64}
    //         alt="Uploaded Preview"
    //         style={{ width: "100px", marginTop: "10px" }}
    //       />
    //     </div>
    //   )}
    // </div>
  );
};

export default ImageUploader;
