import React, { useState, useEffect } from "react";
import { accountSideMenu } from "../../global/menus";
import { Link, Outlet, useLocation } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { useGlobalProvider } from "../../global/provider";
import Skeleton from "react-loading-skeleton";
import { useAuth } from "../../contexts/auth";
import profilePic from "../../assets/static/profilePic/profilePic.png";
import "react-loading-skeleton/dist/skeleton.css";
import "./components/account.css";

const Account = () => {
  const { globalDs } = useGlobalProvider();
  const { signOut } = useAuth();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <section className="relative lg:pb-24 pb-16 bgMenu">
      <div className="container relative mt-16">
        <div className="md:flex topspace">
          <div className="lg:w-1/4 md:w-1/3 md:px-3">
            <div className="relative md:-mt-48 -mt-32">
              <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 sideBar">
                <div className="profile-pic text-center mb-5">
                  <div>
                    <div className="relative h-28 w-28 mx-auto">
                      {loading ? (
                        <Skeleton circle={true} height={112} width={112} />
                      ) : (
                        <img
                          src={profilePic}
                          className="rounded-full shadow dark:shadow-gray-800 ring-4
                          ring-slate-50 dark:ring-slate-800"
                          id="profile-image"
                          alt=""
                        />
                      )}
                      <label
                        className="absolute inset-0 cursor-pointer"
                        htmlFor="pro-img"
                      ></label>
                    </div>

                    <div className="mt-4">
                      {loading ? (
                        <>
                          <Skeleton width={100} />
                          <Skeleton width={150} />
                        </>
                      ) : (
                        <>
                          <h5 className="text-lg font-semibold">
                            {globalDs?.personalDetails?.user_name}
                          </h5>
                          <p className="text-slate-400 truncateWord">
                            {globalDs?.personalDetails?.email}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className="border-t border-gray-100 dark:border-gray-700">
                  <ul
                    className="list-none sidebar-nav mb-0 pb-0"
                    id="navmenu-nav"
                  >
                    {loading
                      ? [...Array(4)].map((_, index) => (
                          <li key={index}>
                            <Skeleton height={40} />
                          </li>
                        ))
                      : accountSideMenu &&
                        accountSideMenu.map((data) => (
                          <li
                            className={`navbar-item account-menu ${
                              location.pathname === `/account/${data.route}`
                                ? "active"
                                : ""
                            }`}
                            key={data.id}
                          >
                            {data.name === "Sign Out" ? (
                              <button
                                onClick={signOut}
                                className="navbar-link text-slate-400 flex items-center py-2 rounded bg-transparent border-0 cursor-pointer"
                              >
                                <span className="me-2 mb-0">
                                  <FeatherIcon
                                    icon={data.icon}
                                    className="size-4"
                                  />
                                </span>
                                <h6 className="mb-0 font-medium">
                                  {data.name}
                                </h6>
                              </button>
                            ) : (
                              <Link
                                to={data.route}
                                className="navbar-link text-slate-400 flex items-center py-2 rounded"
                              >
                                <span className="me-2 mb-0">
                                  <FeatherIcon
                                    icon={data.icon}
                                    className="size-4"
                                  />
                                </span>
                                <h6 className="mb-0 font-medium">
                                  {data.name}
                                </h6>
                              </Link>
                            )}
                          </li>
                        ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="lg:w-3/4 md:w-2/3 md:px-3 mt-6 md:mt-0 bg-white rounded">
            <Outlet />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Account;
