const login = `auth/login`;
const registration = `auth/registration`;
const tempUser = `auth/tempUser`;
const forgotPassword = `auth/forgetPassword`;
const resetPassword = `auth/resetPassword`;
const viewCategory = `category/getCategoryDetails`;
const popularAndNewArrival = `products/getNewArrivalAndPopularProducts`;
const addWishList = `wishlist/add_to_wishlist`;
const updateAccountDetails = `account/account_update`;
const addAccountDetails = `account/add_account_details`;
const personalDetails = `account/person_information`;
const addRatings = `ratings/add_rating_by_products`;
const addToCartApi = `cart/add_to_cart`;
const tempOrder = `payment/temp_order`;
const createOrder = `payment/create_order`

const getRatingsByProduct = (product_id, category_id) =>
  `ratings/get_ratings_by_products?category_id=${category_id}&product_id=${product_id}`;
const getWishList = (auth_user_id) =>
  `wishlist/get_wishlist?auth_user_id=${auth_user_id}`;
const deleteWishlist = (auth_user_id, products_id) =>
  `wishlist/delete_wishlist?auth_user_id=${auth_user_id}&products_id=${products_id}`;
const productDetailsById = (id, page, limit) =>
  `products/getProductDetailsById?category_Id=${id}&page=${page}&limit=${limit}`;
const productDetailViewByProductId = (categoryId, productId) =>
  `products/getProductDetailedViewById?category_Id=${categoryId}&products_Id=${productId}`;
const userDetails = (id) => `account/account_details?id=${id}`;
const getAccountDetails = (auth_user_id) =>
  `account/getall_account_details?auth_user_id=${auth_user_id}`;
const deleteAccountDetails = (account_id) =>
  `account/delete_account_details?account_id=${account_id}`;
const deleteAccount = (id) => `account/deactivate_account?id=${id}`;
const getCartList = (id, session_id) =>
  `cart/get_cart_list?auth_user_id=${id}&session_id=${session_id}`;
const updateCartDetails = (id, session_id, qty, product_id) =>
  `cart/update_cart?auth_user_id=${id}&session_id=${session_id}&qty=${qty}&product_id=${product_id}`;
const deleteCartDetails = (id, session_id, product_id) =>
  `cart/delete_cart?auth_user_id=${id}&session_id=${session_id}&product_id=${product_id}`;
const getCartCount = (id, session_id) =>
  `cart/cart_count?auth_user_id=${id}&session_id=${session_id}`;
const globalSearch = (query) => `search/globalSearch?query_string=${query}`;

const getOrdersDetails =  (id) => `account/get_my_orders?auth_user_id=${id}`

export {
  login,
  registration,
  tempUser,
  forgotPassword,
  resetPassword,
  viewCategory,
  popularAndNewArrival,
  addWishList,
  personalDetails,
  addAccountDetails,
  updateAccountDetails,
  addRatings,
  addToCartApi,
  tempOrder,
  createOrder,
  getAccountDetails,
  deleteAccount,
  deleteAccountDetails,
  getWishList,
  deleteWishlist,
  userDetails,
  productDetailsById,
  productDetailViewByProductId,
  getRatingsByProduct,
  getCartList,
  updateCartDetails,
  deleteCartDetails,
  getCartCount,
  globalSearch,
  getOrdersDetails
};
