import { useCallback } from "react";
import { makeGetRequest, makePostRequest } from "../Api/requestMethods";
import { somethingWentWrong } from "../global/constants";
import { toast } from "react-toastify";
import { addToCartApi, getCartCount } from "../Api/api";
import { getSessionId, isValidValue } from "../utils/reUsableFun";

const useAddToCart = () => {
  const addToCart = useCallback(
    async (user, session_id, product_id, values) => {
      try {
        const body = {
          product_id: product_id,
          qty: values.qty,
          auth_user_id: user?.id || null,
          session_id: user?.id ? "" : session_id,
          image: values?.image,
          attributeType: values?.attributeType,
          real_price: values?.real_price,
          discount_price: values?.discount_price,
          product_name : values?.product_name,
          product_image : values?.product_image
        };
        const response = await makePostRequest(addToCartApi, body);
        return response;
      } catch (error) {
        toast.error(somethingWentWrong);
      }
    },
    []
  );

  const fetchCountCount = useCallback(async (user, data) => {
    try {
      const response = await makeGetRequest(
        getCartCount(user?.id || null),
        getSessionId()
      );
      return response;
    } catch (error) {
      toast.error(somethingWentWrong);
    }
  }, []);

  return { addToCart, fetchCountCount };
};
export default useAddToCart;
