import React, { useEffect, useState } from "react";
import { makeGetRequest } from "../../../Api/requestMethods";
import { useQuery } from "@tanstack/react-query";
import { getOrdersDetails } from "../../../Api/api";
import { useAuth } from "../../../contexts/auth";
import { IndianRupeeSymbol } from "../../../global/constants";
import { formatDatetochar } from "../../../utils/reUsableFun";
import OffCanvasRight from "./rightCanvas";
import { toast } from "react-toastify";

const Orders = () => {
  const [isOffCanvasOpen, setIsOffCanvasOpen] = useState(false);
  const [selectedPurchase, setSelectedPurchase] = useState(null);
  const { user } = useAuth();
  const fetchOrdersDetails = async () =>
    await makeGetRequest(getOrdersDetails(user?.id));
  const { data: ordersDetails } = useQuery({
    queryKey: ["__orders_details__"],
    queryFn: () => fetchOrdersDetails(),
  });


  const handleOverViewOrders = (data) => {
    try {
      setSelectedPurchase(data);
      setIsOffCanvasOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <h5 className="text-lg font-semibold mb-6">My Orders</h5>
      <div className="relative overflow-x-auto shadow dark:shadow-gray-800 rounded-md">
        <table className="w-full text-start text-slate-500 dark:text-slate-400">
          <thead className="text-sm uppercase bg-slate-50 dark:bg-slate-800">
            <tr className="text-start">
              <th scope="col" className="px-2 py-3 text-start">
                Order Id
              </th>
              <th scope="col" className="px-2 py-3 text-start">
                Date
              </th>
              <th scope="col" className="px-2 py-3 text-start">
                Status
              </th>
              <th scope="col" className="px-2 py-3 text-start">
                Total
              </th>
              <th scope="col" className="px-2 py-3 text-start">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {ordersDetails &&
              ordersDetails?.data?.map((data, index) => (
                <tr
                  className={
                    index === 0
                      ? "bg-white dark:bg-slate-900 text-start"
                      : "bg-white dark:bg-slate-900 text-start border-t border-gray-100 dark:border-gray-700"
                  }
                  key={index}
                  onClick={() => handleOverViewOrders(data)}
                >
                  <th className="px-2 py-3 text-start" scope="row">
                    {data?.razorpay_order_id}
                  </th>
                  <td className="px-2 py-3 text-start">
                    {formatDatetochar(data?.order_date)}
                  </td>
                  <td className="px-2 py-3 text-start text-green-600">
                    {data?.order_status}
                  </td>
                  <td className="px-2 py-3 text-start">
                    {IndianRupeeSymbol} {data.total_amount}{" "}
                    <span className="text-slate-400">
                      for {data?.order_items?.length} items
                    </span>
                  </td>
                  <td className="px-2 py-3 text-start">
                    <a href="javascript:void(0)" className="text-orange-500">
                      View <i className="mdi mdi-chevron-right"></i>
                    </a>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {selectedPurchase && (
          <OffCanvasRight
            isOpen={isOffCanvasOpen}
            onClose={() => setIsOffCanvasOpen(false)}
            selectedPurchase={selectedPurchase}
          />
        )}
      </div>
    </>
  );
};

export default Orders;
