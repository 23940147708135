// const BASEPATH = `http://172.20.10.3:3002`;
// const IPCONFIG = `http://172.20.10.3`;

// const adminCategoryImagePath = `${IPCONFIG}:8082/static/category_images/`;
// const adminProductsImagePath = `${IPCONFIG}:8082/static/products_images/`;
// const productImagePath = `${IPCONFIG}:8082/static/products_images/`;


const BASEPATH = `https://api.planet56.in`;
const IPCONFIG = `https://adminapi.planet56.in`

const adminCategoryImagePath = `${IPCONFIG}/static/category_images/`;
const adminProductsImagePath = `${IPCONFIG}/static/products_images/`
const productImagePath = `${IPCONFIG}/static/products_images/`

export {
    BASEPATH,
    adminCategoryImagePath,
    productImagePath,
    adminProductsImagePath
}
