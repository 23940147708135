const Login_form = {
  email: "",
  otp: "",
  isOTPverification: false,
};

const registration_form = {
  user_name: "",
  email: "",
  password: "",
  country_code: "+91",
  mobile_number: "",
};

const temp_user = {
  user_name: "",
  email: "",
  otp: "",
  isotpEnabled: false,
  password: "",
};

const personalDetails_form = {
  user_name: "",
  last_name: "",
  gender: "",
  id: null,
};

const account_details_form = {
  name: "",
  auth_user_id: null,
  mobile: null,
  country: "India",
  state: "",
  city: "",
  pincode: "",
  address: "",
  landmark: "",
  alternative_mobile: null,
  address_type: null,
};

export const account_details_checkout_form = {
  name: "",
  email : "",
  mobile: null,
  country: "India",
  state: "",
  city: "",
  pincode: "",
  address: "",
  landmark: "",
  alternative_mobile: null,
  address_type_isHome: null,
};


const rating_form = {
  category_id: "",
  product_id: "",
  ratings: 5,
  reviews: "",
  rated_by: null,
  rated_by_name: "",
};

const commonComponent_form = {
  image: "",
  product_image : "",
  attributeType: "",
  qty: "",
  product_name : "",
  real_price: null,
  discount_price: null,
};

export {
  rating_form,
  temp_user,
  Login_form,
  registration_form,
  personalDetails_form,
  account_details_form,
  commonComponent_form,
};
