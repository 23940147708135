import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { temp_user } from "../../schemas/Forms";
import { tempUser_validation } from "../../schemas/Validations";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { postFetch } from "../../utils/reUsableFun";
import { useFormik } from "formik";
import { tempUser } from "../../Api/api";
import { useGlobalProvider } from "../../global/provider";
import { useAuth } from "../../contexts/auth";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import registerImage from "../../assets/static/LogIN/SignIN.png";

const ConfirmEmail = ({ handleSwitchPopup, handleClose }) => {
  const navigate = useNavigate();
  const { globalDs, setGlobalDs } = useGlobalProvider();
  const { createToken } = useAuth();
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: temp_user,
    validationSchema: tempUser_validation,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await tempUserMutation.mutateAsync(values);
      setSubmitting(false);
    },
  });

  const tempUserMutation = useMutation({
    mutationFn: (regData) => postFetch(tempUser, regData),
    onSuccess: (data) => {
      if (data.status === 200 || data.status === 201) {
        if (!data.isTokenAvail) {
          toast.success(data.message);
          setGlobalDs((prev) => ({ ...prev, otpEnabled: true }));
          setFieldValue("isotpEnabled", true);
        } else {
          toast.success(data.message);
          createToken(data);
          resetForm();
          setGlobalDs((prev) => ({ ...prev, isLoginEnabled: false }));
          navigate("/");
          window.location.reload(false);
        }
      } else {
        toast.error(data.message);
        setFieldValue("isotpEnabled", false);
        setFieldValue("otp", "");
        setGlobalDs((prev) => ({ ...prev, otpEnabled: false }));
      }
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
      setFieldValue("isotpEnabled", false);
      setGlobalDs((prev) => ({ ...prev, otpEnabled: false }));
    },
  });

  useEffect(() => {
    if (!globalDs.otpEnabled) setFieldValue("otp", "");
  }, [globalDs.otpEnabled]);

  return (
    <>
      <div class="grid grid-cols-1">
        <div class="relative overflow-hidden rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
          <IoMdClose
            style={{
              position: "absolute",
              top: "20px",
              right: "20px",
              fontSize: "24px",
              cursor: "pointer",
            }}
            onClick={handleClose}
          />
          <div class="d-flex items-center">
            <div class="relative w-auto d-none d-lg-block">
              <img
                class="h-full object-cover LoginImg"
                src={registerImage}
                alt=""
                style={{ width: "400px", height: "auto" }}
              />
            </div>

            <div class="p-8 lg:px-20">
              <div class="grid grid-cols-1">
                <div class="mb-4">
                  <label class="font-semibold">Your Name:</label>
                  <input
                    type="text"
                    class="mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                    placeholder="Your name"
                    value={values.user_name}
                    onChange={(e) =>
                      handleChange({
                        target: {
                          name: "user_name",
                          value: e.target.value,
                        },
                      })
                    }
                    onBlur={(e) =>
                      handleBlur({
                        target: {
                          name: "user_name",
                          value: e.target.value,
                        },
                      })
                    }
                  />
                  {touched.user_name && errors.user_name ? (
                    <div className="fieldRequiredError text-red-500 text-sm">
                      {errors.user_name}
                    </div>
                  ) : null}
                </div>

                <div class="mb-4">
                  <label class="font-semibold">Email Address:</label>
                  <input
                    type="email"
                    class="mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                    placeholder="Your Email"
                    value={values.email}
                    onChange={(e) =>
                      handleChange({
                        target: { name: "email", value: e.target.value },
                      })
                    }
                    onBlur={(e) =>
                      handleBlur({
                        target: { name: "email", value: e.target.value },
                      })
                    }
                  />
                  {touched.email && errors.email ? (
                    <div className="fieldRequiredError text-red-500 text-sm">
                      {errors.email}
                    </div>
                  ) : null}
                </div>

                {globalDs.otpEnabled && (
                  <div class="mb-4">
                    <label class="font-semibold">OTP:</label>
                    <input
                      type="number"
                      class="mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                      placeholder="Your otp"
                      value={values.otp}
                      onChange={(e) =>
                        handleChange({
                          target: { name: "otp", value: e.target.value },
                        })
                      }
                      onBlur={(e) =>
                        handleBlur({
                          target: { name: "otp", value: e.target.value },
                        })
                      }
                    />
                    {globalDs.otpEnabled && touched.otp && errors.otp ? (
                      <div className="fieldRequiredError text-red-500 text-sm">
                        {errors.otp}
                      </div>
                    ) : null}
                  </div>
                )}

                <div class="mb-4">
                  <div class="flex items-center w-full mb-0">
                    <label
                      class="form-check-label text-slate-400"
                      for="AcceptT&C"
                    >
                      By continuing, you agree to planet56{" "}
                      <Link>Terms of Use</Link> and <Link>Privacy Policy.</Link>
                    </label>
                  </div>
                </div>

                <div class="mb-4">
                  <button
                    class="py-2 px-5 inline-block tracking-wide align-middle duration-500 text-base text-center bg-orange-500 text-white rounded-md w-full"
                    onClick={handleSubmit}
                    value="Register"
                    disabled={isSubmitting}
                    type="button"
                  >
                    {globalDs.otpEnabled ? "Verify OTP" : "Register"}
                  </button>
                </div>

                <div class="text-center">
                  <span class="text-slate-400 me-2">
                    Already have an account ?{" "}
                  </span>{" "}
                  <button
                    onClick={() => handleSwitchPopup("login")}
                    disabled={isSubmitting}
                  >
                    Sign in
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmEmail;
