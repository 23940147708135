import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { makeGetRequest } from "../../Api/requestMethods";
import { productDetailViewByProductId } from "../../Api/api";
import ImageGallery from "./components/ImageGallery";
import "./productDetailedView.css";
import { isValidArrayWithData, isValidValue } from "../../utils/reUsableFun";
import { IndianRupeeSymbol } from "../../global/constants";
import ReviewAndRatings from "../../components/reviewAndRatings/reviewAndRatings";
import CommonComponent from "./components/commonComponent";
import { useGlobalProvider } from "../../global/provider";
import { useFormik } from "formik";
import { SessionExistsOrCreate } from "../../utils/reUsableFun";
import { commonComponent_form } from "../../schemas/Forms.js";
import { commonComponent_validation } from "../../schemas/Validations.js";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/auth.js";
import { statusSuccess } from "../../global/constants.js";
import useAddToCart from "../../hooks/useAddToCart.js";
import { scrollToTop } from "../../utils/reUsableFun";
import { adminProductsImagePath } from "../../config/config.js";
import useWishlist from "../../hooks/useWishList.js";
import { encryptData } from "../../utils/cryptoUtils";

const ProductDetailedView = () => {
  const { addToCart, fetchCountCount } = useAddToCart();
  const [isShopNow, setShowEvent] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();
  const { addToWishlist, removeFromWishlist, updateWishlist } = useWishlist();
  const { globalDs, setGlobalDs } = useGlobalProvider();
  const [searchParams] = useSearchParams();
  const category_id = searchParams.get("cid");
  const product_id = searchParams.get("pid");
  const [dataSource, setDataSource] = useState({
    categoryId: null,
    productsId: null,
    mainImage: "",
    isDescriptionActive: true,
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues: commonComponent_form,
    validationSchema: commonComponent_validation,
    onSubmit: async (values, { setSubmitting }) => {
      await handleProductSubmit();
      setSubmitting(false);
    },
  });

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleProductSubmit = async () => {
    try {
      const session_id = SessionExistsOrCreate();
      if (!user) {
        setGlobalDs((prev) => ({ ...prev, isLoginEnabled: true }));
        return;
      }
      const response = await addToCart(
        user || null,
        session_id,
        product_id,
        values
      );
      if (response.status === statusSuccess) {
        const counterResponse = await fetchCountCount(user);
        setGlobalDs((prev) => ({
          ...prev,
          cartCount:
            counterResponse === 0
              ? counterResponse
              : counterResponse?.data?.count,
        }));
        isShopNow ? navigate("/checkout") : toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error, "erorr");
    }
  };

  const fetchQuery = async () =>
    await makeGetRequest(productDetailViewByProductId(category_id, product_id));
  const { data: ProductDataSource } = useQuery({
    queryKey: ["getCategoryDetailsById", category_id, product_id],
    queryFn: () => fetchQuery(),
    enabled: !!category_id && !!product_id,
  });

  const productImages =
    ProductDataSource &&
    ProductDataSource.data &&
    ProductDataSource.data[0] &&
    JSON.parse(ProductDataSource?.data[0]?.products_image);

  const productDetails =
    ProductDataSource &&
    isValidArrayWithData(ProductDataSource.data) &&
    ProductDataSource.data[0];

  const onImageUpload = (base64Image) => {
    setFieldValue("image", base64Image);
    setFieldValue("product_image", productImages ? productImages[0] : "");
  };

  console.log(productImages, "productImages");
  console.log(values, "valuers");

  useEffect(() => {
    if (isValidValue(productDetails?.products_name)) {
      setFieldValue("product_name", productDetails?.products_name);
    }
  }, [productDetails]);

  const attributeChangeEvent = (attribute) => {
    try {
      const filterAttributeValues = isValidArrayWithData(
        ProductDataSource?.data[0].category_attributes
      )
        ? ProductDataSource?.data[0].category_attributes?.filter(
            (data) => data.attributeType === attribute
          )
        : [];
      if (isValidArrayWithData(filterAttributeValues)) {
        const { real_price, discount_price } = filterAttributeValues[0];
        setFieldValue("real_price", real_price);
        setFieldValue("discount_price", discount_price);
      }
    } catch (error) {
      // notify
    }
  };

  useEffect(() => {
    attributeChangeEvent(values.attributeType);
  }, [values.attributeType]);

  const addToWishlisting = (data, isWishlistItem) => {
    try {
      isWishlistItem
        ? removeFromWishlist(data)
        : addToWishlist(data, isWishlistItem);
    } catch (error) {}
  };

  const productDetailEvent = (categoryId, productId) => {
    navigate(
      `/ProductDetailedView?cid=${encryptData(categoryId)}&pid=${encryptData(
        productId
      )}`
    );
    scrollToTop();
  };

  return (
    <>
      <section className="relative md:py-24 py-16">
        <div className="container relative mt-10">
          <div className="grid md:grid-cols-2 grid-cols-1 gap-6 items-center">
            {productImages && isValidArrayWithData(productImages) ? (
              <>
                <ImageGallery productImages={productImages} />
              </>
            ) : null}
            <div className="">
              <h5 className="text-2xl font-semibold">
                {" "}
                {productDetails?.products_name}
              </h5>
              <div className="mt-2">
                <span className="text-slate-400 font-semibold me-1">
                  {IndianRupeeSymbol}{" "}
                  {values.real_price === null
                    ? productDetails?.real_price
                    : values.real_price}{" "}
                  <del className="text-red-600">
                    {IndianRupeeSymbol}{" "}
                    {values.discount_price === null
                      ? productDetails?.fake_price
                      : values.discount_price}
                  </del>
                </span>

                {/* Ratings and feedbacks */}

                <ul
                  className="list-none inline-block
text-orange-400"
                >
                  <li className="inline">
                    <i
                      className="mdi
mdi-star text-lg"
                    ></i>
                  </li>
                  <li className="inline">
                    <i
                      className="mdi
mdi-star text-lg"
                    ></i>
                  </li>
                  <li className="inline">
                    <i
                      className="mdi
mdi-star text-lg"
                    ></i>
                  </li>
                  <li className="inline">
                    <i
                      className="mdi
mdi-star text-lg"
                    ></i>
                  </li>
                  <li className="inline">
                    <i
                      className="mdi
mdi-star text-lg"
                    ></i>
                  </li>
                  <li
                    className="inline text-slate-400
font-semibold"
                  >
                    4.8 (45)
                  </li>
                </ul>
              </div>
              <CommonComponent
                product_id={product_id}
                productDetails={productDetails}
                attributeChangeEvent={attributeChangeEvent}
                handleSubmit={handleSubmit}
                setShowEvent={setShowEvent}
                handleBlur={handleBlur}
                handleChange={handleChange}
                values={values}
                setFieldValue={setFieldValue}
                onImageUpload={onImageUpload}
                touched={touched}
                errors={errors}
              />
            </div>
          </div>
          {/* <div className="mt-4">
            <h5 className="text-lg font-semibold">Overview :</h5>
            <p className="text-slate-400 mt-2">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero
              exercitationem, unde molestiae sint quae inventore atque minima
              natus fugiat nihil quisquam voluptates ea omnis. Modi laborum
              soluta tempore unde accusantium.
            </p>
          </div> */}

          <div className="grid md:grid-cols-12 grid-cols-1 mt-6 gap-6">
            <div className="lg:col-span-3 md:col-span-5">
              <div className="sticky top-20">
                <ul
                  className="flex-column p-6 bg-white
dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md"
                  id="myTab"
                  data-tabs-toggle="#myTabContent"
                  role="tablist"
                >
                  <li
                    role="presentation"
                    onClick={() =>
                      setDataSource((prev) => ({
                        ...prev,
                        isDescriptionActive: true,
                      }))
                    }
                  >
                    <button
                      className={`px-4 py-2
text-start text-base font-semibold rounded-md w-full
hover:text-orange-500 duration-500 ${
                        dataSource.isDescriptionActive ? "active_button" : ""
                      }`}
                      id="description-tab"
                      data-tabs-target="#description"
                      type="button"
                      role="tab"
                      aria-controls="description"
                      aria-selected="true"
                    >
                      Description
                    </button>
                  </li>

                  <li
                    role="presentation"
                    onClick={() =>
                      setDataSource((prev) => ({
                        ...prev,
                        isDescriptionActive: false,
                      }))
                    }
                  >
                    <button
                      className={`px-4 py-2
text-start text-base font-semibold rounded-md w-full mt-3
duration-500 ${!dataSource.isDescriptionActive ? "active_button" : ""}`}
                      id="review-tab"
                      data-tabs-target="#review"
                      type="button"
                      role="tab"
                      aria-controls="review"
                      aria-selected="false"
                    >
                      Review
                    </button>
                  </li>
                </ul>
              </div>
            </div>

            <div className="lg:col-span-9 md:col-span-7">
              <div
                id="myTabContent"
                className="p-6 bg-white
dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md"
              >
                {dataSource.isDescriptionActive ? (
                  <div
                    className=""
                    id="description"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <p className="text-slate-400">
                      {productDetails?.product_description}
                    </p>
                  </div>
                ) : (
                  <ReviewAndRatings
                    product_id={product_id}
                    category_id={category_id}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="container lg:mt-24 mt-16">
          <div className="grid grid-cols-1 mb-6 text-center">
            <h3
              className="font-semibold text-3xl
leading-normal"
            >
              New Arrival Items
            </h3>
          </div>

          <div
            className="grid lg:grid-cols-4 md:grid-cols-3
sm:grid-cols-2 grid-cols-1 gap-6 pt-6"
          >
            {ProductDataSource &&
              ProductDataSource.recommendation &&
              ProductDataSource.recommendation.map((data) => {
                const matchingWishlistItem =
                  globalDs.wishlistDataSource &&
                  globalDs.wishlistDataSource.length > 0 &&
                  globalDs.wishlistDataSource.find(
                    (wishlistItem) =>
                      wishlistItem.products_id === data.products_id
                  );
                const isWishlistItem = !!matchingWishlistItem;
                return (
                  <div className="group">
                    <div
                      className="relative overflow-hidden
shadow dark:shadow-gray-800 group-hover:shadow-lg
group-hover:dark:shadow-gray-800 rounded-md duration-500"
                      onClick={() =>
                        productDetailEvent(data?.category_id, data?.products_id)
                      }
                    >
                      <img
                        src={`${adminProductsImagePath}${
                          data?.products_image
                            ? JSON.parse(data.products_image)[0]
                            : ""
                        }`}
                        className="group-hover:scale-110 duration-500"
                        alt=""
                      />
                      <ul className="list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 duration-500 space-y-1">
                        <li>
                          <div
                            className={`size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full ${
                              isWishlistItem
                                ? "bg-green-500 text-white hover:bg-green-600"
                                : "bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"
                            }`}
                            onClick={(e) => {
                              e.stopPropagation();
                              addToWishlisting(data, isWishlistItem);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-heart size-4"
                              style={{
                                color: isWishlistItem ? "#8cc33f" : "red",
                              }}
                            >
                              <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
                            </svg>
                          </div>
                        </li>
                      </ul>
                      <ul className="list-none absolute top-[10px] start-4">
                        <li>
                          <p className="bg-orange-600 text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5">
                            {data?.discount_percentage}% Off
                          </p>
                        </li>
                      </ul>
                    </div>

                    <div className="mt-4">
                      <a
                        href="product-detail-one.html"
                        className="hover:text-orange-500 text-lg font-medium"
                      >
                        {data?.products_name}
                      </a>
                      <div
                        className="flex justify-between
items-center mt-1"
                      >
                        <p>
                          {IndianRupeeSymbol} {data?.real_price}{" "}
                          <del className="text-slate-400">
                            {IndianRupeeSymbol} {data?.fake_price}
                          </del>
                        </p>
                        <ul
                          className="font-medium
text-amber-400 list-none"
                        >
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                          <li className="inline">
                            <i className="mdi mdi-star"></i>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductDetailedView;
