import React, { useState } from "react";
import { useFormik } from "formik";
import { Login_form } from "../../schemas/Forms";
import { login_validation } from "../../schemas/Validations";
import { postFetch } from "../../utils/reUsableFun";
import { login } from "../../Api/api";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useAuth } from "../../contexts/auth";
import Modal from "react-bootstrap/Modal";
import { useGlobalProvider } from "../../global/provider";
import ForgotPasswordModal from "./forgotPasswordModal";
import ConfirmEmail from "../createAccountForm/tempUser";
import { IoMdClose } from "react-icons/io";
import logIN from "../../assets/static/LogIN/LogIN.png";

const LoginFormModal = () => {
  const [popupControls, setPopupControls] = useState({
    isRegistraionEnabled: false,
    isForgotPasswordEnabled: false,
    otpEnabled: false,
  });
  const { createToken } = useAuth();
  const { globalDs, setGlobalDs } = useGlobalProvider();
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: Login_form,
    validationSchema: login_validation,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await loginMutation.mutateAsync(values);
      setSubmitting(false);
    },
  });

  const loginMutation = useMutation({
    mutationFn: async (loginData) => {
      const response = await postFetch(login, loginData);
      return response;
    },

    onSuccess: async (data) => {
      if (data && !data.isotpVerified) {
        setFieldValue("isOTPverification", true);
        setPopupControls((prev) => ({ ...prev, otpEnabled: true }));
        toast.success(data.message);
        return;
      }
      await createToken(data);
      setGlobalDs((prev) => ({ ...prev, isLoginEnabled: false }));
      toast.success(data.message);
      setFieldValue("isOTPverification", false);
    },

    onError: async (error) => {
      const errorMessage = error?.response?.data?.message;
      setGlobalDs((prev) => ({ ...prev, isLoginEnabled: false }));
      setFieldValue("isOTPverification", false);
      toast.error(errorMessage);
    },
  });

  const handleClose = () => {
    setGlobalDs((prev) => ({
      ...prev,
      isLoginEnabled: false,
      otpEnabled: false,
    }));
    setPopupControls((prev) => ({
      ...prev,
      isRegistraionEnabled: false,
      isForgotPasswordEnabled: false,
      otpEnabled: false,
    }));
    resetForm();
  };

  const handleSwitchPopup = (condtion) => {
    if (condtion === "registration") {
      setPopupControls((prev) => ({
        ...prev,
        isRegistraionEnabled: true,
        isForgotPasswordEnabled: false,
      }));
    }

    if (condtion === "forgot") {
      setPopupControls((prev) => ({
        ...prev,
        isForgotPasswordEnabled: true,
        isRegistraionEnabled: false,
      }));
    }

    if (condtion === "login") {
      setPopupControls((prev) => ({
        ...prev,
        isForgotPasswordEnabled: false,
        isRegistraionEnabled: false,
      }));
    }
    resetForm();
  };

  return (
    <>
      <Modal size="lg" show={globalDs.isLoginEnabled}>
        {!popupControls.isRegistraionEnabled &&
        !popupControls.isForgotPasswordEnabled ? (
          <div className="grid grid-cols-1">
            <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
              <IoMdClose
                style={{
                  position: "absolute",
                  top: "20px",
                  right: "20px",
                  fontSize: "24px",
                  cursor: "pointer",
                }}
                onClick={handleClose}
              />

              <div className="d-flex items-center">
                <div className="relative w-auto d-none d-md-block">
                  <img
                    className="h-full object-cover LoginImg"
                    src={logIN}
                    alt=""
                    style={{ width: "280px", height: "auto" }}
                  />
                </div>

                <div className="p-8 lg:px-20">
                  <form className="text-start lg:py-10 py-8">
                    <div className="grid grid-cols-1">
                      <div className="mb-4">
                        <label className="font-semibold" htmlFor="LoginEmail">
                          Email Address:
                        </label>
                        <input
                          id="LoginEmail"
                          type="text"
                          className="mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                          placeholder="name@example.com"
                          value={values.email}
                          onChange={(e) =>
                            handleChange({
                              target: { name: "email", value: e.target.value },
                            })
                          }
                          onBlur={(e) =>
                            handleBlur({
                              target: { name: "email", value: e.target.value },
                            })
                          }
                        />
                        {touched.email && errors.email ? (
                          <div className="fieldRequiredError text-red-500 text-sm">
                            {errors.email}
                          </div>
                        ) : null}
                      </div>
                      {popupControls.otpEnabled && (
                        <div class="mb-4">
                          <label class="font-semibold">OTP:</label>
                          <input
                            type="number"
                            class="mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                            placeholder="Your otp"
                            value={values.otp}
                            onChange={(e) =>
                              handleChange({
                                target: { name: "otp", value: e.target.value },
                              })
                            }
                            onBlur={(e) =>
                              handleBlur({
                                target: { name: "otp", value: e.target.value },
                              })
                            }
                          />
                          {touched.otp && errors.otp ? (
                            <div className="fieldRequiredError text-red-500 text-sm">
                              {errors.otp}
                            </div>
                          ) : null}
                        </div>
                      )}

                      <div className="mb-4">
                        <button
                          className="py-2 px-5 inline-block tracking-wide align-middle duration-500 text-base text-center bg-orange-500 text-white rounded-md w-full"
                          onClick={handleSubmit}
                          disabled={isSubmitting}
                        >
                          {!popupControls.otpEnabled
                            ? "Login / Sign in"
                            : "Submit OTP"}
                        </button>
                      </div>

                      <div className="text-center">
                        <button
                          onClick={() => handleSwitchPopup("registration")}
                        >
                          <span className="text-slate-400 me-2">
                            Don't have an account?
                          </span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        ) : popupControls.isRegistraionEnabled ? (
          <ConfirmEmail
            handleSwitchPopup={handleSwitchPopup}
            handleClose={handleClose}
          />
        ) : null}
      </Modal>
    </>
  );
};

export default LoginFormModal;
