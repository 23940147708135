import React, { useState } from "react";
import { IndianRupeeSymbol } from "../../../global/constants";
import { adminProductsImagePath } from "../../../config/config";
import { Offcanvas, Nav, Tab, Row, Col } from "react-bootstrap";
import { useGlobalProvider } from "../../../global/provider";
import { Package, IndianRupee, MapPin, Mail, Phone, Home, Globe } from 'lucide-react';


const OffCanvasRight = ({ isOpen, onClose, selectedPurchase }) => {
  const { globalDs } = useGlobalProvider();
  const [activeTab, setActiveTab] = useState("orderDetails");


  const AddressField = ({ icon: Icon, label, value }) => {
    if (!value) return null;
    return (
      <div className="flex items-start gap-3 p-2">
        <div className="text-gray-400 mt-1">
          <Icon size={16} />
        </div>
        <div>
          <span className="text-sm text-gray-500">{label}</span>
          <p className="text-gray-700 font-medium">{value}</p>
        </div>
      </div>
    );
  };


  const renderOrderItems = () => {
    if (
      !selectedPurchase?.order_items ||
      selectedPurchase.order_items.length === 0
    ) {
      return <p>No order items found.</p>;
    }
    return (
      <div className="order-items-container border rounded-3">
        <div className="p-4 border-b border-gray-100">
        <div className="flex items-center gap-2">
          <Package className="text-gray-400" size={20} />
          <h3 className="text-lg font-semibold text-gray-800">Order Items</h3>
        </div>
      </div>
        {selectedPurchase.order_items.map((item, index) => (
          <div key={index} className="order-item mb-3 mx-2 p-2 border rounded">
            <Row>
              <Col xs={4}>
                {item.image && (
                  <img
                    src={`${adminProductsImagePath}${item.product_img}`}
                    alt={`Product ${item.products_id}`}
                    className="img-fluid rounded-3"
                  />
                )}
              </Col>
              <Col xs={8}>
                <div className="">
                  <strong>{item?.product_name} - {item?.attributeType}</strong>
                </div>
                
                <div className="inline-flex items-center py-1 bg-blue-50 text-blue-700 rounded-full text-sm">
                Quantity: {item.quantity}
              </div>
                  
                <div>
                  Price :  {IndianRupeeSymbol}{" "}
                  {item.real_price} <small className="text-decoration-line-through text-secondary">{item.discount_price}</small>
                </div>
                {/* <div>
                  <strong>Discount Price : </strong> {IndianRupeeSymbol}{" "}
                 
                </div> */}
              </Col>
            </Row>
          </div>
        ))}
      </div>
    );
  };

  // Render address details
  const renderAddressDetails = () => {
    const address = selectedPurchase?.shipping_address;
    if (!address) {
      return <p>No address information available.</p>;
    }
    return (
      <div className="address-details-container border rounded-3 px-2">
        <div className="p-4 space-y-2">
        {/* Primary Details */}
        <div className="mb-4">
          <h4 className="font-medium text-gray-700 mb-2">{address.name}</h4>
          <div className="inline-flex items-center px-2 py-1 bg-blue-50 text-blue-700 rounded-full text-sm">
            Shipping Address
          </div>
        </div>

        {/* Contact Information */}
        <div className="grid grid-cols-1 md:grid-cols-1 gap-2">
          <AddressField icon={Mail} label="Email" value={address.email} />
          
          <AddressField icon={Phone} label="Phone" value={address.mobile} />
        </div>

        {/* Address Information */}
        <div className="pt-3 border-t border-gray-100">
          <AddressField
            icon={Home}
            label="Street Address"
            value={address.address}
          />
          <AddressField icon={MapPin} label="Area" value={address.landmark} />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            <AddressField
              icon={Globe}
              label="City"
              value={`${address.city}, ${address.state} ${address.pincode}`}
            />
            <AddressField
              icon={Globe}
              label="Country"
              value={address.country}
            />
          </div>
        </div>
      </div>
      </div>
    );
  };

  return (
    <div className="orderPopup">
    <Offcanvas show={isOpen} onHide={onClose} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Order Details</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Tab.Container
          id="order-details-tabs"
          activeKey={activeTab}
          onSelect={(k) => setActiveTab(k)}
        >
          <Nav variant="tabs" className="mb-3">
            <Nav.Item>
              <Nav.Link eventKey="orderDetails">Order Items</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="addressDetails">Shipping Address</Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey="orderDetails">{renderOrderItems()}</Tab.Pane>
            <Tab.Pane eventKey="addressDetails">
              {renderAddressDetails()}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Offcanvas.Body>
    </Offcanvas>
    </div>
  );
};

export default OffCanvasRight;
