import React from "react";
import { Link } from "react-router-dom";

const PaymentFailed = () => {
  return (
    <div>
      <div className="payment">
        {/* <div>
          <video
             style={{ maxWidth:"100%" }}
             width="auto"
             height="300vh"
            autoPlay
            muted
            onEnded={(e) => e.target.pause()} 
          >
            <source src={successVideo} type="video/mp4" />
          </video>
        </div> */}
        <h3 className="mb-3" style={{ color: "#fe6037" }}>
          <b>Payment Failed!</b>
        </h3>
        <p className="mb-5" style={{ color: "#fe6037" }}>
          {/* Please call 99401 52520 for support. */}
        </p>
        <Link
          to="/"
          className="text-decoration-none text-decoration-underline"
          style={{ color: "blue" }}
        >
          Back to home
        </Link>
      </div>
    </div>
  );
};

export default PaymentFailed;
